import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\nBASE_URL = "/";\nIS_LOGGEDIN = "0";\nSITE_URL = BASE_URL;\nLANG_BASE_URL = "/";\n'
          }}
        />

        <script
          async
          type="text/javascript"
          src="/static/js/generated_js/958a58972bf272cd5ead1fe8b4a225e1.js?=1588071981"
        />
        <script async typ="text/javascript" src="/static/js/generated_js/jquery-migrate-1.4.1-min.js" />
        <div id="layout">
          <div className="head">
            <div className="inner">
              <a className="logo" href="/">
                <img src="/static/i/layout/New_Logo.png" alt="VISINE" />
              </a>
              <div className="menuHolder">
                <ul id="menu">
                  <li className="mainMenu">
                    <a href="/">Főoldal</a>
                  </li>
                  <li className="mainMenu">
                    <a href="/voros-szemre/">Vörös szemre</a>
                  </li>
                  <li className="mainMenu">
                    <a href="/termekeink/visine-classic/">
                      VISINE<sup>®</sup> Rapid
                    </a>
                  </li>
                  <li className="mainMenu">
                    <a href="/szemtorna/">Szemtorna</a>
                  </li>
                  <li className="mainMenu">
                    <a href="/kapcsolat/">Kapcsolat</a>
                  </li>
                </ul>
              </div>
              <div className="clear" />
            </div>
          </div>
          <div id="content" className="content">
            <div id="productsPage">
              <div className="inner">
                <h1>Termékeink</h1>
                <div className="productsHolder">
                  <div className="product">
                    <a
                      className="productImage"
                      href="/termekeink/visine-classic/"
                    >
                      <img
                        src="/static/i/products/visine_classic_135x250.png"
                        alt="VISINE Rapid 0,5 mg/ml oldatos szemcsepp"
                      />
                    </a>
                    <div className="textHolder">
                      <div className="texts">
                        <div className="productName">
                          <a href="/termekeink/visine-classic/">
                            VISINE<sup>®</sup> Rapid <br />
                            0,5 mg/ml oldatos szemcsepp
                          </a>
                        </div>
                        <div className="desc">
                          Percek alatt csökkenti a szem vörösségét, hatása akár
                          8 órán át tart.
                        </div>
                      </div>
                      <a href="/termekeink/visine-classic/" className="btn red">
                        Tudjon meg többet!
                      </a>
                    </div>
                  </div>
                  <div className="clear" />
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
          <div className="footer">
            <div className="inner">
              <div className="productStatus">
                <div className="contentInner">
                  <div className="footerInner">
                    VISINE<sup>®</sup> Rapid 0,5 mg/ml oldatos szemcsepp. Vény
                    nélkül kapható, tetrizolin-hidroklorid hatóanyagú gyógyszer.
                  </div>
                </div>
              </div>
              <div className="footerMenu">
                <ul>
                  <li>
                    <a href="/jogi-nyilatkozat/">Jogi nyilatkozat</a>
                  </li>
                  <li>
                    <a href="/adatvedelmi-szabalyzat/">
                      Adatvédelmi szabályzat
                    </a>
                  </li>
                  <li>
                    <a href="/oldalterkep/">Oldaltérkép</a>
                  </li>
                  <li>
                    <a href="/cookie-szabalyzat/">Cookie szabályzat</a>
                  </li>
                  <li>
                    <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie-beállítások</a>
                  </li>
                </ul>
              </div>
              <div className="johnson">
                <div className="contentInner">
                  <div className="footerInner">
                    <p className="bigger">©JNTL CONSUMER HEALTH (HUNGARY) KFT., 2023. 1112 Budapest, BudapestOne Center Balatoni út 2. a. ép. 1. em.</p>
                    <p>
                      Ezt az oldalt a JNTL Consumer Health (Hungary) Kft. tette közzé, és
                      felel annak tartalmáért.
                    </p>
                    <p>
                      Ez az oldal magyarországi felhasználók számára készült.
                    </p>
                    <p>Utoljára frissítve: 2023.02.01.</p>
                    <p>HU-VI-2200008</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="risks">
            <div className="contentInner">
              <div className="footerInner">
                A kockázatokról és a mellékhatásokról olvassa el a
                betegtájékoztatót, vagy kérdezze meg kezelőorvosát,
                gyógyszerészét!
              </div>
            </div>
          </div>
        </div>


      </div>
    );
  }
}

export default Page;
